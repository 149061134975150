// @flow
import React from 'react';
import Flex from '@waglabs/flex';
import styled, { keyframes } from 'styled-components';

type Props = {
  onClick: () => void,
};

const MODAL_OPACITY = 0.4;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: ${MODAL_OPACITY};
  }
`;

const Container = styled(Flex)`
  transition: transform 300ms ease-in-out;
  animation: ${fadeIn} 0.3s ease-in-out;
`;

const Overlay = ({
  onClick,
  ...props
}: Props) => (
  <Container
    opacity={MODAL_OPACITY}
    onClick={onClick}
    hover
    {...props}
  />
);

Overlay.defaultProps = {
  bg: 'black',
  bottom: '0',
  height: '100vh',
  left: '0',
  position: 'fixed',
  right: '0',
  top: '0',
  zIndex: '1',
  opacity: MODAL_OPACITY,
};

export default Overlay;
