// @flow

/**
 * Returns a partial copy of an object omitting the keys specified.
 */
export const omit = (propertyKeys: string[]) => (object: Object) =>
  Object.entries(object).reduce(
    (acc, [key, val]) =>
      propertyKeys.includes(key)
        ? acc
        : {
            ...acc,
            [key]: val,
          },
    {}
  );
