// @flow
import React from 'react';
import Box from '@waglabs/box';
import styled from 'styled-components';

type Props = {};

const FormFieldErrorMessage = (props: Props) => (
  <StyledBox
    {...props}
  />
);

const StyledBox = styled(Box)`
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
`;

FormFieldErrorMessage.defaultProps = {
  paddingTop: 0,
  mr: '5px',
  height: '23px',
  maxWidth: '85%',
  ml: 'auto',
  px: [1, 2],
  color: 'white',
  bg: 'wagRed',
  fontFamily: 'body',
  fontSize: [6, 6, 7, 7],
};

export default FormFieldErrorMessage;
