export const createControl = key => objControl => ({ [key]: objControl });

// Deprecation helper
export const deprecate = (properties = [], path = '', cb = undefined) => (obj) => {
  const target = path ? obj[path] : obj;
  const proxiedObj = new Proxy(target, {
    get: (targetObj, prop) => {
      const isPropDeprecated = !properties.length ? true : properties.includes(prop);
      if (isPropDeprecated) {
        /**
         * Take note that even if we clean up the components from using it,
         * we should also remove the "spreading" of base colors from other
         * themes. Ex: (petco.theme ->  ...BaseTheme.colors)
         */
        if (cb) {
          // eslint-disable-next-line no-console
          console.warn(cb(prop));
        } else {
          // eslint-disable-next-line no-console
          console.warn(`Accessing "${prop}" will soon be deprecated. Please avoid using it.`);
        }
      }
      return targetObj[prop];
    },
  });
  if (path) {
    // eslint-disable-next-line no-param-reassign
    obj[path] = proxiedObj;
    return obj;
  }
  return proxiedObj;
};

export const pipe = (...fns) => initialValue => fns.reduce((a, cv) => cv(a), initialValue);
