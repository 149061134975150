// @flow

export const getDigits = (value: string) => String(value).replace(/\D/g, '');

export const formatDate = (date: string) => {
  const digits = getDigits(date).substring(0, 8);

  const month = digits.substring(0, 2);
  const day = digits.substring(2, 4);
  const year = digits.substr(4, 8);

  if (digits.length > 4) {
    return `${month}/${day}/${year}`;
  }

  if (digits.length > 2) {
    return `${month}/${day}`;
  }

  if (digits.length > 0) {
    return month;
  }

  return '';
};

export default formatDate;
