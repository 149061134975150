import styled from 'styled-components';
import {
  fontFamily,
  fontSize,
  width,
  display,
  height,
  maxWidth,
  minWidth,
  space,
  borders,
  borderColor,
  color,
  textAlign,
  lineHeight,
  fontWeight,
  letterSpacing,
} from 'styled-system';

const Link = styled.a`
  ${fontFamily}
  ${fontSize}
  ${width}
  ${height}
  ${maxWidth}
  ${minWidth}
  ${space}
  ${borders}
  ${color}
  ${textAlign}
  ${lineHeight}
  ${fontWeight}
  ${letterSpacing}
  ${borderColor}
  ${display}
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-indent: ${props => props.textIndent};
  text-transform: ${props => (props.textTransform || 'none')};
  font-style: ${props => props.fontStyle || 'normal'};
  white-space: ${props => props.whiteSpace || 'normal'};
  opacity: ${props => props.opacity || '1'};
  box-sizing: ${props => props.boxSizing || 'content-box'};
  text-decoration: ${props => (props.textDecoration || 'none')};
  transition: ${props => props.transition || 'none'};

  &:hover {
    cursor: pointer;
    color: ${props => props.color || props.theme.colors.wagGreen || 'blue'};
    ${props => props.hoverColor && `
      color: ${props.hoverColor};
    `}
  }
`;

Link.defaultProps = {
  href: '#',

};

export default Link;
