// @flow
import React from 'react';
import styled from 'styled-components';
import Flex from '@waglabs/flex';
import { getIsMobileOrTabletDevice } from './getIsMobileOrTabletDevice';

const StyledPage = styled(Flex)`
  ${props => props.landscapeDisabled && getIsMobileOrTabletDevice() && `
    @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
      transform: rotate(-90deg);
      transform-origin: left top;
      width: 100vh;
      height: 100vw;
      overflow-x: hidden;
      position: absolute;
      top: 100%;
      left: 0;
    }
  `}

  backface-visibility: hidden;
  filter: grayscale(0%);
`;

type Props = {
  children: any,
  landscapeDisabled?: boolean,
};

const Page = ({
  children,
  landscapeDisabled,
  ...props
}: Props) => (
  <StyledPage
    alignItems="center"
    flexDirection="column"
    height="100vh"
    landscapeDisabled={landscapeDisabled}
    isAnimationContainer
    {...props}
  >
    {children}
  </StyledPage>
);

Page.defaultProps = {
  landscapeDisabled: false,
};

export default Page;
