// @flow
import React from 'react';
import { StyledTextInput } from '..';
import { AnimatedInputLabel } from '../..';


type Props = {
  field: any,
  form: any,
  id: string,
  isFirst: boolean,
  label: string,
  name: String,
  isTouched: boolean,
  isValid: boolean,
  onChange: (SyntheticEvent<*>) => void,
  onFocus: (SyntheticEvent<*>) => void,
  onBlur: (SyntheticEvent<*>) => void,
  value: string,
};

type State = {
  isFocused: boolean,
}

class TextInputField extends React.Component<Props, State> {
  state = {
    isFocused: false,
  }

  handleFocus = (e: SyntheticEvent<*>) => {
    const {
      onFocus,
    } = this.props;

    if (onFocus) {
      onFocus(e);
    }

    this.setState({ isFocused: true });
  }

  handleBlur = (e: SyntheticEvent<*>) => {
    const {
      onBlur,
    } = this.props;

    if (onBlur) {
      onBlur(e);
    }

    this.setState({ isFocused: false });
  }

  render() {
    const {
      isFirst,
      isTouched,
      isValid,
      label,
      name,
      value,
      ...props
    } = this.props;

    const {
      isFocused,
    } = this.state;

    return (
      <>
        {/* Label */}
        <AnimatedInputLabel
          isFocused={value.length > 0 || isFocused}
          name={name}
        >
          {label}
        </AnimatedInputLabel>

        {/* Input */}
        <StyledTextInput
          error={isTouched && !isValid}
          isValid={isValid}
          name={name}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
          pt={(value.length > 0 || isFocused) ? '15px' : 0}
          touched={isTouched}
          value={value}
          {...props}
        />
      </>
    );
  }
}

export default TextInputField;
