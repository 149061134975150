// @flow
import React, { Component } from 'react';
import { StripeProvider } from 'react-stripe-elements';
import type { StripeShape } from 'stripe';

type Props = {
  children: React$Element<*>,
};
type State = {
  stripe: null | StripeShape,
};

const key = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
if (!key) {
  throw new Error('Invalid Stripe public key');
}

class WithStripe extends Component<Props, State> {
  _isMounted = false;
  state = {
    stripe: null,
  };

  componentDidMount() {
    this._isMounted = true;
    if (!window.Stripe) {
      const stripeJs = document.createElement('script');

      stripeJs.id = 'stripe';
      stripeJs.src = 'https://js.stripe.com/v3/';
      stripeJs.async = true;
      stripeJs.onload = () => {
        if (this._isMounted) {
          this.setState({
            stripe: window.Stripe(key),
          });
        }
      };

      if (document.body) {
        document.body.appendChild(stripeJs);
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.killStripe();
  }

  killStripe = () => {
    if (this._isMounted) {
      this.setState({
        stripe: null,
      });
    }

    window.Stripe = null;

    if (document.body) {
      const stripeJs = document.getElementById('stripe');

      // $FlowFixMe flow thinks document.body can be null below
      if (stripeJs) {
        document.body.removeChild(stripeJs);
      }
    }
  };

  render() {
    const {
      children,
    } = this.props;

    const {
      stripe,
    } = this.state;

    return (
      <StripeProvider
        stripe={stripe}
      >
        {children}
      </StripeProvider>
    );
  }
}

export default WithStripe;
