// @flow
import React from 'react';
import { Field } from 'formik';

type Props = {};

export const FormField = (props: Props) => (
  <Field
    {...props}
  />
);

export default FormField;
