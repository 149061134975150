// @flow
import React from 'react';
import { TextInputField } from '@waglabs/control';
import {
  FormField,
  FormFieldContainer,
  FormFieldErrorMessage,
} from '..';

type InputProps = {
  isTouched: boolean,
  isValid: boolean,
  label: string,
  name: string,
  onBlur: (SyntheticEvent<*>) => void,
  onChange: (SyntheticEvent<*>) => void,
  onFocus: (SyntheticEvent<*>) => void,
  type: string,
  value: string,
}

type Props = {
  containerProps: Object,
  errorMessageProps?: Object,
  hasErrorMessage?: boolean,
  isFirst?: Boolean,
  label: string,
  renderComponent: (InputProps) => React$Node,
  type: string,
  onBlur: (SyntheticEvent<*>) => void,
  onChange: (SyntheticEvent<*>) => void,
  onFocus: (SyntheticEvent<*>) => void,
};

export const TextInputFormField = ({
  containerProps,
  errorMessageProps,
  hasErrorMessage,
  isFirst,
  label,
  renderComponent,
  type,
  onBlur: propsOnBlur,
  onChange: propsOnChange,
  onFocus: propsOnFocus,
  ...props
}: Props) => (
  <FormField
    {...props}
    render={({
      field: {
        name,
        onBlur: formikOnBlur,
        onChange: formikOnChange,
        onFocus: formikOnFocus,
        value,
      },
      form: {
        touched,
        errors,
      },
    }) => {
      const isTouched = touched[name];
      const isValid = !errors[name];
      const errorMessage = errors[name];

      // Must call both props and formik handlers to avoid namespace conflicts
      const onBlur = (...values) => {
        if (formikOnBlur) formikOnBlur(...values);
        if (propsOnBlur) propsOnBlur(...values);
      };
      const onFocus = (...values) => {
        if (formikOnFocus) formikOnFocus(...values);
        if (propsOnFocus) propsOnBlur(...values);
      };
      const onChange = (...values) => {
        if (formikOnChange) formikOnChange(...values);
        if (propsOnChange) propsOnChange(...values);
      };

      return (
        <FormFieldContainer
          isFirst={isFirst}
          {...containerProps}
        >
          {/* If we pass a render props for a custom component */}
          {renderComponent && renderComponent({
            containerProps,
            isTouched,
            isValid,
            label,
            name,
            onBlur,
            onChange,
            onFocus,
            type,
            value,
            ...props,
          })}

          {/* Default component if no render props are passed */}
          {!renderComponent && (
            <TextInputField
              containerProps={containerProps}
              isTouched={isTouched}
              isValid={isValid}
              label={label}
              name={name}
              onBlur={onBlur}
              onChange={onChange}
              onFocus={onFocus}
              type={type}
              value={value}
              {...props}
            />
          )}

          {/* Error Message */}
          {hasErrorMessage
            && isTouched
            && !isValid
            && errorMessage
            && (
              <FormFieldErrorMessage
                {...errorMessageProps}
              >
                {errorMessage}
              </FormFieldErrorMessage>
            )
          }
        </FormFieldContainer>
      );
    }}
  />
);

TextInputFormField.defaultProps = {
  hasErrorMessage: true,
  isFirst: false,
  onBlur: () => {},
  onChange: () => {},
  onFocus: () => {},
};

export default TextInputFormField;
