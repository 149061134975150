// @flow
import React from 'react';
import { TextAreaField } from '@waglabs/control';
import {
  FormField,
  FormFieldContainer,
  FormFieldErrorMessage,
} from '..';

type InputProps = {
  isTouched: boolean,
  isValid: boolean,
  label: string,
  name: string,
  onBlur: (SyntheticEvent<*>) => void,
  onChange: (SyntheticEvent<*>) => void,
  onFocus: (SyntheticEvent<*>) => void,
  type: string,
  value: string,
}

type Props = {
  containerProps: Object,
  errorMessageProps?: Object,
  hasErrorMessage?: boolean,
  isFirst?: Boolean,
  label: string,
  renderComponent: (InputProps) => React$Node,
  type: string,
};

export const TextAreaFormField = ({
  containerProps,
  errorMessageProps,
  hasErrorMessage,
  isFirst,
  label,
  renderComponent,
  type,
  ...props
}: Props) => (
  <FormField
    {...props}
    render={({
      field: {
        name,
        onBlur,
        onChange,
        onFocus,
        value,
      },
      form: {
        touched,
        errors,
      },
    }) => {
      const isTouched = touched[name];
      const isValid = !errors[name];
      const errorMessage = errors[name];

      return (
        <FormFieldContainer
          isFirst={isFirst}
          {...containerProps}
        >
          {/* If we pass a render props for a custom component */}
          {renderComponent && renderComponent({
            containerProps,
            isTouched,
            isValid,
            label,
            name,
            onBlur,
            onChange,
            onFocus,
            type,
            value,
            ...props,
          })}

          {/* Default component if no render props are passed */}
          {!renderComponent && (
            <TextAreaField
              containerProps={containerProps}
              isTouched={isTouched}
              isValid={isValid}
              label={label}
              name={name}
              onBlur={onBlur}
              onChange={onChange}
              onFocus={onFocus}
              type={type}
              value={value}
              {...props}
            />
          )}

          {/* Error Message */}
          {hasErrorMessage
            && isTouched
            && !isValid
            && (
              <FormFieldErrorMessage
                {...errorMessageProps}
              >
                {errorMessage}
              </FormFieldErrorMessage>
            )
          }
        </FormFieldContainer>
      );
    }}
  />
);

TextAreaFormField.defaultProps = {
  hasErrorMessage: true,
  isFirst: false,
};

export default TextAreaFormField;
