// @flow
import * as React from 'react';
import styled from 'styled-components';
import {
  borderColor,
  borderRadius,
  borders,
  boxShadow,
  color,
  fontFamily,
  fontSize,
  fontWeight,
  height,
  lineHeight,
  space,
  textAlign,
  variant,
  width,
} from 'styled-system';
import {
  formatDate,
  formatLockboxCode,
  formatName,
  formatPassword,
  formatPhoneNumber,
  formatState,
  formatZipCode,
} from '../formatters';

const textInputStyle = variant({
  key: 'textInputs',
});

const StyledTextInput = styled.input.attrs({
  type: 'text',
})`
  /* Variants should be placed at the top to be override */
  ${textInputStyle}
  ${borderRadius}
  ${borders}
  ${borderColor}
  ${boxShadow}
  ${color}
  ${fontFamily}
  ${fontSize}
  ${fontWeight}
  ${height}
  ${lineHeight}
  ${space}
  ${textAlign}
  ${width}
  box-sizing: border-box;
  font-family: ${props => props.theme[props.fontFamily] || 'Helvetica, Arial, sans-serif'};
  display: ${props => props.display || 'inline'};
  text-indent: ${props => props.textIndent || '0'};

  background-color: ${props => props.theme.colors.form.input.inactiveBackground};
  border: 1px solid ${props => props.theme.colors.form.input.inactiveBorder};
  color: ${props => props.theme.colors.form.input.inactiveColor};

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px ${props => props.autocompleteBackground || props.theme.colors.backgroundGray} inset !important;
    -webkit-text-fill-color: ${props => props.theme.colors.form.input.inactiveColor} !important;
  }

  ${props => props.error && `
    border: 1px solid ${props.theme.colors.form.errorBorder || 'red'};
  `}

  ${props => props.isFocused && `
    ${(!props.noBorderOnFocus
    ? `border: 2px solid ${props.theme.colors.wagGray || '#aaaaaa'};`
    : 'border: none;')}
    background-color: ${props.theme.colors.white || '#ffffff'};
  `}

  ${props => props.value && `
    background-color: ${props.theme.colors.form.input.activeBackground};
    border: 1px solid ${props.theme.colors.form.input.activeBorder};
    color: ${props.theme.colors.form.input.activeColor};
  `}

  &:focus {
    ${props => props.isFocused && `
      outline: none;
      background-color: ${props.theme.colors.form.input.activeBackground};
      border: 1px solid ${props.theme.colors.form.input.activeBorder};
      color: ${props.theme.colors.form.input.activeColor};
    `}
  }

  &::placeholder {
    ${fontSize}
    color: ${props => props.theme.colors.form.input.inactiveColor};
    font-family: ${props => props.theme[props.fontFamily] || 'Helvetica, Arial, sans-serif'};
  }
`;

class TextInput extends React.Component {
  static defaultProps = {
    variant: 'default',
  };

  state = {
    isFocused: false,
  }

  inputRef = undefined;

  selection = {
    start: false,
    end: false,
  };

  componentDidUpdate() {
    const {
      selectionStart,
      selectionEnd,
    } = this.inputRef;

    // We need this to combat some issues with Safari moving the input cursor
    const update = (this.selection.start !== false && this.selection.start !== selectionStart)
      || (this.selection.end !== false && this.selection.end !== selectionEnd);

    if (update) {
      this.inputRef.selectionStart = this.selection.start;
      this.inputRef.selectionEnd = this.selection.end;
    }
  }

  handleRef = (ref: any) => {
    const {
      onRef,
    } = this.props;

    if (onRef) {
      onRef(ref);
    }

    this.inputRef = ref;
  }

  handleBlur = (e) => {
    const {
      onBlur,
    } = this.props;

    if (onBlur) {
      onBlur(e);
    }

    this.setState({ isFocused: false });
  }

  handleFocus = (e) => {
    const {
      onFocus,
    } = this.props;

    if (onFocus) {
      onFocus(e);
    }

    this.setState({ isFocused: true });
  }

  handleChange = (e) => {
    const {
      onChange,
      type,
    } = this.props;

    const input = this.inputRef;

    this.selection = {
      start: input.selectionStart,
      end: input.selectionEnd,
    };

    if (onChange) {
      const value = this.transformValueByType(e.target.value, type);

      if (e.target.value.length !== value.length) {
        const difference = value.length - e.target.value.length;
        this.selection = {
          start: input.selectionStart + difference,
          end: input.selectionEnd + difference,
        };
      }

      e.target.value = value;

      onChange(e);
    }
  }

  transformValueByType = (value, type) => {
    if (!type) {
      return value;
    }

    switch (type.toLowerCase()) {
      case 'tel':
      case 'phone':
        return formatPhoneNumber(value);

      case 'zip':
      case 'zipcode':
      case 'zipCode':
        return formatZipCode(value);

      case 'name':
        return formatName(value);

      case 'state':
        return formatState(value);

      case 'lockboxcode':
        return formatLockboxCode(value);

      case 'password':
        return formatPassword(value);

      case 'wag-date':
        return formatDate(value);

      default:
        return value;
    }
  };

  render() {
    const {
      value,
      variant: inputVariant,
      touched,
      onRef,
      validFieldIconName,
      textInputVariant,
      ...props
    } = this.props;

    const {
      isFocused,
    } = this.state;

    return (
      <StyledTextInput
        height={50}
        width={1}
        fontSize={4}
        fontFamily="body"
        boxShadow="none"
        {...props}
        variant={inputVariant}
        isFocused={isFocused}
        ref={this.handleRef}
        value={value}
        onFocus={this.handleFocus}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
      />
    );
  }
}

export default TextInput;
