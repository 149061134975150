// @flow
import React from 'react';
import Box from '@waglabs/box';
import Label from '@waglabs/label';
import styled from 'styled-components';

const LabelContainer = styled(Box)`
  left: 8px;
  position: absolute;
  top: 13px;
  transition: all 0.2s ease-in-out;
  pointer-events: none;
  transform: translate3d(0,0,0);

  ${props => props.isFocused && `
    left: 10px;
    top: 0;
  `}
`;

const TextInputLabel = styled(Label)`
  transition: all 0.2s ease-in-out;
  font-size: ${props => props.theme.fontSizes[4] || 18}px;
  ${props => props.isFocused && `
    font-size: ${props.theme.fontSizes[2] || 12}px
  `}
`;

type Props = {
  isFocused: boolean,
  children: any,
};

const AnimatedInputLabel = ({
  children,
  isFocused,
  name,
}: Props) => (
  <LabelContainer
    isFocused={isFocused}
  >
    <TextInputLabel
      color="wagGray"
      fontFamily="body"
      fontSize={2}
      htmlFor={name}
      isFocused={isFocused}
    >
      {children}
    </TextInputLabel>
  </LabelContainer>
);

export default AnimatedInputLabel;
