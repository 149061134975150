// @flow
import React from 'react';
import Box from '@waglabs/box';
import Label from '@waglabs/label';
import { Select } from '..';

type Props = {
  dataTestId: string,
  error: string,
  isSearchable: Boolean,
  label: string,
  onBlur: (SyntheticEvent<*>) => void,
  onChange: (SyntheticEvent<*>) => void,
  onFocus: (SyntheticEvent<*>) => void,
  options?: {
    key: string,
    label: string,
    value: string,
  }[],
  placeholder: string,
  value: string,
};

const SelectField = ({
  dataTestId,
  error,
  isSearchable,
  label,
  onBlur,
  onChange,
  onFocus,
  options,
  placeholder,
  value,
  ...props
}: Props) => (
  <Box
    width="100%"
    position="relative"
  >
    {label && (
      <Label>
        {label}
      </Label>
    )}

    <Select
      borderRadius={3}
      boxShadow="none"
      data-testid={dataTestId}
      error={error}
      fontFamily="body"
      fontSize={4}
      height={50}
      isSearchable={isSearchable}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      options={options}
      placeholder={placeholder}
      textIndent="10px"
      value={options && options.find(option => value === option.value)}
      width={1}
      {...props}
    />
  </Box>
);

SelectField.defaultProps = {
  isSearchable: true,
  options: [],
};

export default SelectField;
