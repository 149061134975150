// @flow
export const getDigits = (value: string) => String(value).replace(/\D/g, '');

export const formatPhoneNumber = (phoneNumberString: string) => {
  const digits = getDigits(phoneNumberString).substring(0, 10);

  const zip = digits.substring(0, 3);
  const middle = digits.substring(3, 6);
  const last = digits.substring(6, 10);

  if (digits.length > 6) {
    return `(${zip}) ${middle}-${last}`;
  }

  if (digits.length > 3) {
    return `(${zip}) ${middle}`;
  }

  if (digits.length > 0) {
    return `(${zip}`;
  }

  return '';
};

export default formatPhoneNumber;
