import React from 'react';
import Box from '@waglabs/box';
import posed from 'react-pose';

const defaultTransition = { duration: 500, ease: 'easeInOut' };

const SlidePose = posed.div({
  preEnter: {
    y: ({ inDirection }) => {
      if (inDirection === 'up') {
        return '-200%';
      }

      if (inDirection === 'down') {
        return '200%';
      }

      return 0;
    },

    x: ({ inDirection }) => {
      if (inDirection === 'left') {
        return '-200%';
      }

      if (inDirection === 'right') {
        return '200%';
      }

      return 0;
    },

    opacity: 0,

    transition: ({ transition }) => transition || defaultTransition,
  },

  enter: {
    x: '0',
    y: '0',
    opacity: 1,
    transition: ({ transition }) => transition || defaultTransition,
  },


  exit: {
    y: ({ outDirection }) => {
      if (outDirection === 'up') {
        return '-200%';
      }

      if (outDirection === 'down') {
        return '200%';
      }

      return 0;
    },

    x: ({ outDirection }) => {
      if (outDirection === 'left') {
        return '-200%';
      }

      if (outDirection === 'right') {
        return '200%';
      }

      return 0;
    },

    opacity: 0,

    transition: ({ transition }) => transition || defaultTransition,
  },
});

const Slide = props => (
  <Box isAnimationContainer>
    <SlidePose {...props} />
  </Box>
);

export default Slide;
