// @flow
import styled from 'styled-components';
import ReactSelect from 'react-select';
import {
  space,
  height,
  display,
  borders,
  borderColor,
  width,
  color,
  maxWidth,
  minWidth,
  minHeight,
  maxHeight,
  alignSelf,
  borderRadius,
  backgroundImage,
  backgroundSize,
  backgroundPosition,
  backgroundRepeat,
  position,
  top,
  right,
  bottom,
  left,
  zIndex,
  boxShadow,
  fontFamily,
  fontSize,
  overflow,
  opacity,
} from 'styled-system';

const Select = styled(ReactSelect)`
  &.styled-select {
    ${space}
    ${width}
    ${height}
    ${borders}
    ${borderColor}
    ${color}
    ${maxWidth}
    ${minWidth}
    ${minHeight}
    ${maxHeight}
    ${borderRadius}
    ${backgroundImage}
    ${backgroundSize}
    ${backgroundPosition}
    ${backgroundRepeat}
    ${display}
    ${top}
    ${right}
    ${bottom}
    ${left}
    ${position}
    ${alignSelf}
    ${zIndex}
    ${fontSize}
    ${fontFamily}
    ${boxShadow}
    ${overflow}
    ${opacity}
    visibility: ${props => props.visibility || 'visible'};
    box-sizing: ${props => props.boxSizing || 'content-box'};
    transition: ${props => props.transition || 'all 0s 0s ease'};
    transform: ${props => props.transform || 'none'};
    text-indent: ${props => props.textIndent || '0'};

    ${props => props.variant === 'clear' && `
      .styled-select__value-container {
        padding-left: 0;
      }

      .styled-select__control {
        height: 30px !important;
      }
    `}

    .styled-select__placeholder {
      color: ${props => props.placeholderColor || props.theme.colors.form.select.inactiveColor};
      font-size: ${props => props.theme.fontSizes[4]}px;
    }

    .styled-select__indicator-separator {
      display: none;
    }

    .styled-select__menu {
      box-shadow: none;
      border: 2px solid ${props => props.theme.colors.form.select.activeOptionsBorder};

      ${props => props.variant === 'clear' && `
        border: 1px solid #EDEDED;
        border-radius: 8px;
        background-color: #FFFFFF;
        box-shadow: 0 2px 12px 0 rgba(0,0,0,0.12);
      `}
    }

    .styled-select__option {
      padding-top: 4px;
      padding-bottom: 4px;

      &--is-focused,
      &--is-selected {
        background: ${props => props.theme.colors.form.select.options.activeBackground};
        color: ${props => props.theme.colors.form.select.options.activeColor};
      }

    }

    .styled-select__control {
      ${color}
      ${borders}
      ${height}
      text-indent: ${props => props.textIndent || '0'};
      background-color: ${props => props.theme.colors.form.select.inactiveBackground};
      border-color: ${props => props.theme.colors.form.select.inactiveBorder};
      color: ${props => props.theme.colors.form.select.inactiveColor};

      .styled-select__value-container {
        padding-left: 20px;
        font-size: 18px;
      }

      &--is-focused {
        ${props => !props.variant && `
          border: 2px solid ${props.theme.colors.form.select.options.activeColor};
        `}
        background-color: ${props => props.theme.colors.form.select.activeBackground};
        border-color: ${props => props.theme.colors.form.select.activeBorder};
        color: ${props => props.theme.colors.form.select.activeColor};

        ${props => props.variant === 'clear' && `
          padding: 0;
        `}

        background: ${props => props.theme.colors.white};
        box-shadow: none;

        .styled-select__value-container {
          background: ${props => props.theme.colors.white};
        }

        .styled-select__placeholder {
          color: ${props => props.theme.colors.form.select.options.inactiveColor};
          margin-left: 0;
        }
        .styled-select__menu-list {
          padding-bottom: unset;
          padding-top: unset;
        }
      }
    }

    /* Select dropdown */
    .styled-select__single-value {
      color: ${props => (props.value ? props.theme.colors.form.select.activeColor : props.theme.colors.form.select.inactiveColor)};
      font-family: ${props => props.theme.fonts.subHeading};
    }

    &:hover {
      cursor: ${props => (props.hover ? 'pointer' : 'auto')};
    }

    ${props => props.error && `
      border: 1px solid ${props.theme.colors.form.errorBorder || 'red'};
      border-radius: 8px;
    `}

    ${props => props.error && props.variant === 'clear' && `
      border: none;
      border-radius: 8px;
    `}
  }
`;

export default Select;
