// @flow
import React, { useEffect, useRef } from 'react';
import { Formik } from 'formik';
import Box from '@waglabs/box';

import { StyledForm } from '..';

type Props = {
  children: any,
  containerProps: any,
  errors: {
    [string]: string,
  },
  formProps: Object,
  initialValues: Object,
  isInitialValid: boolean | Function,
  enableReinitialize?: boolean,
  onSubmit: Function,
  render: Function,
  validationSchema: Object,
};

export const Form = ({
  children,
  containerProps,
  errors,
  initialValues,
  isInitialValid,
  enableReinitialize,
  onSubmit,
  render,
  validationSchema,
  ...props
}: Props) => {
  const formRef = useRef(null);

  useEffect(() => {
    if (formRef && formRef.current && errors) {
      formRef.current.setErrors(errors);
    }
  }, [errors]);


  return (
    <Formik
      ref={formRef}
      initialValues={initialValues}
      isInitialValid={isInitialValid}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
      render={({
        handleSubmit,
        ...form
      }) => (
        <StyledForm
          onSubmit={handleSubmit}
          width="100%"
          {...props}
        >
          <Box
            m="0 auto"
            {...containerProps}
          >
            {render(form)}
          </Box>
        </StyledForm>
      )}
    />
  );
};

export default Form;
