// @flow
import React from 'react';
import Flex from '@waglabs/flex';
import Box from '@waglabs/box';
import Slide from '@waglabs/slide';
import Image from '@waglabs/image';

import modalBottomWaveBackground from './assets/modal-bottom-white-wave.svg';
import modalCloseIcon from './assets/modal-close-icon.svg';

export type Props = {
  containerProps: props,
  contentProps: props,
  disableHeader?: boolean,
  onCloseModalClick: () => void,
  modalBottomContent: () => any,
  modalTopContent: () => any,
  zIndex?: number,
};

const Modal = ({
  containerProps,
  contentProps,
  disableHeader,
  onCloseModalClick,
  modalBottomContent,
  modalTopContent,
  zIndex,
}: Props) => (
  <Box
    width={[1, 430]}
    height={['100%']}
    zIndex={zIndex}
    position="absolute"
    bg="transparent"
    alignItems="center"
    top="50%"
    left="50%"
    transform="translate(-50%, -50%)"
    boxSizing="border-box"
    {...containerProps}
  >
    <Slide
      inDirection="up"
      outDirection="up"
      initialPose="exit"
      pose="enter"
      transition={{ duration: 300, ease: 'easeInOut' }}
    >
      <Flex
        width={1}
        bg="modal.backgroundColor"
        borderRadius={3}
        flexDirection="column"
        {...contentProps}
      >
        {/* modal top */}
        <Flex
          width={1}
          flexDirection="column"
          position="relative"
          boxSizing="border-box"
          alignItems="center"
          flexShrink="0"
          px={3}
          pt={disableHeader ? 2 : 4}
          pb={2}
        >
          {
            !disableHeader && (
              // close button
              <Image
                position="absolute"
                display={['none', 'block']}
                top={10}
                right={10}
                onClick={onCloseModalClick}
                width={25}
                height={25}
                transform="rotate(45deg)"
                hover
                src={modalCloseIcon}
              />
            )
          }
          {modalTopContent && modalTopContent()}
        </Flex>

        {/* modal bottom */}
        {modalBottomContent && (
          <Flex
            width={1}
            height={['270px', '200px']}
            flexDirection="column"
            position="relative"
            alignItems="center"
            justifyContent="center"
            backgroundImage={`url(${modalBottomWaveBackground})`}
            backgroundRepeat="no-repeat"
            boxSizing="border-box"
            borderRadius={3}
            backgroundPosition="bottom"
            backgroundSize="cover"
          >
            {modalBottomContent()}
          </Flex>
        )}
      </Flex>
    </Slide>
  </Box>
);

Modal.defaultProps = {
  zIndex: 1,
};

export default Modal;
