// @flow
import React, { Component } from 'react';
import { SelectField } from '@waglabs/control';
import {
  FormFieldContainer,
  FormFieldErrorMessage,
  FormField,
} from '..';

type Props = {
  containerProps: Object,
  hasErrorMessage?: boolean,
  label: string,
  name: string,
  options: {
    key: string,
    label: string,
    value: string,
  }[],
  field: {
    onChange: Function,
    onFocus: Function,
    onBlur: Function,
  },
};

class SelectFormField extends Component<Props> {
  static defaultProps = {
    hasErrorMessage: true,
  };

  handleChange = (form: any, field: any) => (value: { value: string }) => {
    if (field && field.onChange) {
      form.setFieldValue(field.name, value.value);
    }
  }

  render() {
    const {
      containerProps,
      hasErrorMessage,
      label,
      options,
      name,
    } = this.props;

    return (
      <FormField
        name={name}
        render={({
          field: {
            name: fieldName,
            onBlur,
            onFocus,
            value,
          },
          field,
          form: {
            touched,
            errors,
          },
          form,
        }) => {
          const isTouched = touched[fieldName];
          const isValid = !errors[fieldName];
          const errorMessage = errors[fieldName];

          return (
            <FormFieldContainer
              {...containerProps}
            >
              <SelectField
                label={label}
                name={fieldName}
                onBlur={onBlur}
                onChange={this.handleChange(form, field)}
                onFocus={onFocus}
                options={options}
                value={value}
              />

              {/* Error Message */}
              {hasErrorMessage
                && isTouched
                && !isValid
                && (
                  <FormFieldErrorMessage>
                    {errorMessage}
                  </FormFieldErrorMessage>
                )
              }
            </FormFieldContainer>
          );
        }}
      />
    );
  }
}

export default SelectFormField;
