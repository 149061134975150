// @flow
import React, { Component } from 'react';
import styled from 'styled-components';
import Text from '@waglabs/text';
import { Flex } from '@waglabs/flex-next';
import Autocomplete from 'react-autocomplete';
import { TextInputField } from '..';

const AutocompleteOption = styled(Text)`
  cursor: pointer;
  ${props => props.isHighlighted && `
    background-color:  ${props.theme.colors.backgroundGray};
  `}
`;

class TextInputAutocomplete extends Component {
  // We must pass the refrence thorugh the autocomplete
  // ref handler 'ref'. We should also
  // pass though 'onRef' for external users
  handleRef = ref => (inputRef) => {
    const {
      onRef,
    } = this.props;

    if (ref) {
      ref(inputRef);
    }

    if (onRef) {
      onRef(inputRef);
    }
  }

  render() {
    const {
      form,
      items,
      label,
      inputProps = {},
      onBlur,
      onChange,
      onFocus,
      onSelect,
      type,
      value,
      renderInput,
      autocompleteItemOptionProps,
      autocompleteMenuProps,
      ...props
    } = this.props;

    return (
      <Autocomplete
        getItemValue={item => item.label}
        renderMenu={(menuItems) => {
          if (!items.length) {
            return <div />;
          }

          return (
            <Flex
              bg="white"
              border="1px solid"
              borderColor="wagGray"
              borderRadius={[2]}
              flexDirection="column"
              height={['125px']}
              overflow="auto"
              position="absolute"
              top="30px"
              width="100%"
              zIndex="1"
              {...autocompleteMenuProps}
            >
              {menuItems}
            </Flex>
          );
        }}
        renderItem={(item, isHighlighted) => (
          <AutocompleteOption
            isHighlighted={isHighlighted}
            key={item.key}
            m="0"
            p={[2]}
            px={[3]}
            {...autocompleteItemOptionProps}
          >
            {item.label}
          </AutocompleteOption>
        )}
        renderInput={({
          ref,
          ...restInputProps
        }) => (
          <>
            {renderInput && renderInput({
              label,
              type,
              value,
              onRef: this.handleRef(ref),
              ...props,
              ...restInputProps,
            })}

            {!renderInput && (
              <TextInputField
                label={label}
                type={type}
                value={value}
                {...props}
                {...restInputProps}
                onRef={this.handleRef(ref)}
              />
            )}
          </>
        )}
        wrapperStyle={{
          width: '100%',
          position: 'relative',
        }}
        inputProps={inputProps}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        items={items}
        value={value}
        onSelect={onSelect}
      />
    );
  }
}

export default TextInputAutocomplete;
