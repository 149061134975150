import { createControl, deprecate } from './utils';

const baseColors = {
  aluminium: '#818A92',
  backgroundGray: '#EDEDED',
  backgroundYellow: '#FAE7A8',
  black: '#000000',
  boneWhite: '#E5E0D3',
  champagne: '#FAE7A8',
  charcoal: '#474747',
  chetwodeBlue: '#6B7CAF',
  copyYellow: '#F9CD46',
  darkGreen: '#085E45',
  darkPurple: '#353F5B',
  darkRed: '#51272F',
  iconBlue: '#5082E5',
  lightBrown: '#7B5533',
  lightGrey: '#D1D1D1',
  lighterGrey: '#F5F5F5',
  lightPurple: '#7786B7',
  mapBlue: '#1FAEE3',
  medBrown: '#66D482B',
  medGray: '#727272',
  medGreen: '#009972',
  medPurple: '#3C5180',
  medRed: '#DD364A',
  orange: '#FF794D',
  red: '#ff0000',
  transparent: 'transparent',
  wagGold: '#FAAB43',
  wagGray: '#AAAAAA',
  wagGreen: '#00BF8F',
  wagPurple: '#616A9B',
  wagRed: '#FF5867',
  white: '#FFFFFF',
};

const appBaseColors = {
  primary: baseColors.wagGreen,
  secondary: baseColors.wagRed,
};

const buttonControl = {
  // See variants::button
  clearBackground: baseColors.transparent,
  clearBorder: baseColors.transparent,
  clearColor: baseColors.wagGray,
  clearText: baseColors.white,

  disabledBackground: baseColors.lightGrey,
  disabledBorder: baseColors.lightGrey,
  disabledColor: baseColors.wagGray,

  primary: appBaseColors.primary,
  secondary: appBaseColors.secondary,
};

const textControl = {
  activeTextLink: baseColors.wagGreen,
  clear: baseColors.white,
  discount: baseColors.wagGold,
  destructive: baseColors.wagRed,
  header: baseColors.medGray,
  light: baseColors.wagGray,
  title: baseColors.medPurple,
  word: baseColors.medGray,
  dark: baseColors.black,
};

const flexControl = {
  light: baseColors.aluminium,
  background: baseColors.lightGrey,
  dark: baseColors.black,
  white: baseColors.white,
  transparent: baseColors.transparent,
};

const pickerControl = {
  date: {
    label: baseColors.wagGreen,
    month: {
      activeColor: baseColors.wagGreen,
      inactiveColor: baseColors.wagGray,
    },
    day: {
      pastEventBulletBackgroundColor: baseColors.wagGray,
      futureEventBulletBackgroundColor: baseColors.wagGreen,
      todayColor: baseColors.wagGreen,

      activeColor: baseColors.wagGreen,
      inactiveColor: baseColors.wagGray,

      selectedBackgroundColor: baseColors.wagGreen,
      selectedHighlightedBackgroundColor: 'rgba(0, 191, 143, 0.3)',
      highlightedBackgroundColor: 'rgba(0, 191, 143, 0.13)',
    },
    activeBackground: baseColors.wagGreen,
    activeBorder: baseColors.wagGreen,
    activeColor: baseColors.white,

    inactiveBackground: baseColors.transparent,
    inactiveBorder: baseColors.transparent,
    inactiveColor: baseColors.medGray,

    pendingBackground: baseColors.transparent,
    pendingBorder: baseColors.wagGold,
    pendingColor: baseColors.wagGreen,
  },
  schedule: {
    highDemand: baseColors.orange,
    recommended: baseColors.wagGreen,
  },
  status: {
    pending: baseColors.wagGold,
  },
};

const navigationControl = {
  activeBackground: baseColors.wagGreen,
  inactiveBackground: baseColors.lightGrey,

  label: baseColors.wagGreen,

  circleButtonColor: baseColors.copyYellow,
};

const formControl = {
  errorBorder: baseColors.red,
  errorBackground: baseColors.red,
  errorColor: baseColors.white,
  horizontalRule: baseColors.backgroundGray,

  checkbox: {
    activeBackground: baseColors.wagGreen,
    activeBorder: baseColors.wagGreen,
    activeColor: baseColors.white,

    inactiveBackground: baseColors.lightGrey,
    inactiveBorder: baseColors.lightGrey,
    inactiveColor: baseColors.white,
  },
  input: {
    activeBackground: baseColors.white,
    activeBorder: baseColors.lightGrey,
    activeColor: baseColors.medGray,

    inactiveBorder: baseColors.lightGrey,
    inactiveBackground: baseColors.white,
    inactiveColor: baseColors.wagGray,

    // Extras
    activeIcon: baseColors.wagGreen,
    inactiveIcon: baseColors.lightGrey,

    label: baseColors.lightGrey,

    // The label under the fields
    validHelperLabel: baseColors.lightGrey,
    invalidHelperLabel: baseColors.red,

    // checkmark that shows up
    checkmarkColor: baseColors.lightPurple,
  },
  select: {
    activeBackground: baseColors.white,
    activeBorder: baseColors.lightGrey,
    activeColor: baseColors.medGray,

    activeOptionsBorder: baseColors.lightGrey,

    inactiveBackground: baseColors.white,
    inactiveBorder: baseColors.lightGrey,
    inactiveColor: baseColors.wagGray,
    options: {
      activeBackground: baseColors.wagGreen,
      activeColor: baseColors.white,

      inactiveBackground: baseColors.white,
      inactiveColor: baseColors.wagGray,
    },
  },
  textarea: {
    activeBackground: baseColors.white,
    activeBorder: baseColors.lightGrey,
    activeColor: baseColors.medGray,

    inactiveBackground: baseColors.white,
    inactiveBorder: baseColors.lightGrey,
    inactiveColor: baseColors.wagGray,
  },
};

const serviceControl = {
  tile: {
    walk: {
      title: baseColors.wagGreen,
      subtitle: baseColors.wagGreen,
    },
  },
  slider: {
    bullet: {
      activeBackground: baseColors.medPurple,
      inactiveBackground: baseColors.lightGrey,
    },
  },
};

const walkServiceControl = {
  slider: {
    bullet: {
      activeBackground: baseColors.wagGreen,
      inactiveBackground: baseColors.lightGrey,
    },
  },
};

const pastServicesControl = {
  services: {
    item: {
      unreadColor: baseColors.medGray,
      readColor: baseColors.wagGray,
    },
  },
  walkers: {
    item: {
      color: baseColors.medGray,
    },
  },
};

const bookingSuccessControl = {
  headingText: baseColors.lightPurple,
  bodyText: baseColors.wagGreen,
};

const reportCardControl = {
  walkStatus: {
    background: appBaseColors.primary,
    labeledIconBackground: baseColors.medGreen,
    labelColor: baseColors.white,
  },
  walkerNote: {
    background: baseColors.backgroundGray,
    text: baseColors.medGray,
  },
};

const progressBarControl = {
  normalBackgroundColor: baseColors.medGreen,
  normalForegroundColor: baseColors.white,
  invertedBackgroundColor: baseColors.white,
  invertedForegroundColor: baseColors.wagGreen,
};

const waveControl = {
  textColor: baseColors.white,
  backgroundColor: baseColors.wagGreen,
  backButtonColor: baseColors.white,
};

const rangeInputControl = {
  thumbColor: appBaseColors.primary,
  trackColor: baseColors.backgroundGray,
};

const modalControl = {
  backgroundColor: baseColors.wagGreen,
  secondaryBackgroundColor: baseColors.white,
  textColor: baseColors.white,
};

const badgeControl = {
  backgroundColor: baseColors.wagGreen,
  textColor: baseColors.white,
};

const avatarControl = {
  borderColor: baseColors.wagGreen,
  secondaryBorderColor: baseColors.chetwodeBlue,
};

const paymentControl = {
  creditCard: {
    activeBorder: baseColors.wagGreen,
    inactiveBorder: baseColors.lightGrey,
  },
  promo: {
    activeBorder: baseColors.wagGreen,
    inactiveBorder: baseColors.lightGrey,
  },
  stripeForm: {
    text: {
      inactiveColor: baseColors.backgroundGray,
      activeColor: baseColors.wagGreen,
    },
  },
  summary: {
    activeBorder: baseColors.lightGrey,
    inactiveBorder: baseColors.lightGrey,
  },
};

const walkerProfileControl = {
  avatar: {
    border: baseColors.chetwodeBlue,
  },
  banner: {
    button: {
      contact: {
        background: baseColors.lightPurple,
        border: baseColors.lightPurple,
        color: baseColors.white,
      },
    },
    text: {
      word: baseColors.white,
    },
  },
  readMore: {
    color: baseColors.wagGray,
  },
};

const componentStateColors = Object.assign(
  {},
  createControl('avatar')(avatarControl),
  createControl('badge')(badgeControl),
  createControl('bookingSuccess')(bookingSuccessControl),
  createControl('button')(buttonControl),
  createControl('flex')(formControl),
  createControl('form')(formControl),
  createControl('modal')(modalControl),
  createControl('navigation')(navigationControl),
  createControl('pastServices')(pastServicesControl),
  createControl('payment')(paymentControl),
  createControl('picker')(pickerControl),
  createControl('progressBar')(progressBarControl),
  createControl('rangeInput')(rangeInputControl),
  createControl('reportCard')(reportCardControl),
  createControl('service')(serviceControl),
  createControl('text')(textControl),
  createControl('walkerProfile')(walkerProfileControl),
  createControl('walkService')(walkServiceControl),
  createControl('wave')(waveControl),
);


const breakpoints = [
  '640px', // 40em
  '832px', // 52em
  '1024px', // 64em
  '1920px', // 120em
];

const space = [
  0, // 0
  5, // 1
  10, // 2
  20, // 3
  40, // 4
  60, // 5
];

const border = {
  borders: [
    0,
    '1px solid',
    '2px solid',
  ],
  radii: [
    0, // 0
    2, // 1
    4, // 2
    8, // 3
  ],
};

const typography = {
  // fonts, fontSizes, fontWeights, lineHeights, letterSpacings

  // QUESTION: How do we import fonts? Typekit? Typography.com?
  fonts: {
    heading: '"Helvetica Neue Bold", Helvetica, Arial, sans-serif',
    subHeading: '"Helvetica Neue Bold", Helvetica, Arial, sans-serif',
    body: '"Helvetica Neue Regular", Helvetica, Arial, sans-serif',
    title: '"Grilled Cheese"',
  },
  fontSizes: [
    8, // 0
    10, // 1
    12, // 2
    16, // 3
    18, // 4
    20, // 5
    22, // 6
    24, // 7
    26, // 8
    32, // 9
    36, // 10
    40, // 11
    48, // 12
  ],
  fontWeights: [
    200, // 0
    300, // 1
    400, // 2
    500, // 3
    600, // 4
    700, // 5
    800, // 6
    900, // 7
  ],
  lineHeights: [
    1,
    1.125,
    1.25,
    1.5,
  ],
  letterSpacings: [],
};

const variants = {
  buttons: {
    clear: {
      backgroundColor: componentStateColors.button.clearBackground,
      borderColor: componentStateColors.button.clearBorder,
      borderStyle: 'solid',
      color: componentStateColors.button.clearColor,
    },
    primary: {
      /**
       * @deprecated - Should use `primary.contained` or `primary.outlined`
       */
      backgroundColor: baseColors.champagne,
      color: baseColors.lightBrown,

      contained: {
        backgroundColor: componentStateColors.button.primary,
        borderColor: componentStateColors.button.primary,
        borderStyle: 'solid',
        color: componentStateColors.button.clearText,
      },
      outlined: {
        backgroundColor: componentStateColors.button.clearBackground,
        borderColor: componentStateColors.button.primary,
        borderStyle: 'solid',
        color: componentStateColors.button.primary,
      },
    },
    alternate: {
      // "alternate primary" - champagne primary button
      contained: {
        backgroundColor: baseColors.champagne,
        borderColor: baseColors.champagne,
        borderStyle: 'solid',
        color: baseColors.lightBrown,
      },
      outlined: {
        backgroundColor: componentStateColors.button.clearBackground,
        borderColor: baseColors.lightBrown,
        borderStyle: 'solid',
        color: baseColors.lightBrown,
      },
    },
    secondary: {
      contained: {
        backgroundColor: componentStateColors.button.secondary,
        borderColor: componentStateColors.button.secondary,
        borderStyle: 'solid',
        color: componentStateColors.button.clearText,
      },
      outlined: {
        backgroundColor: componentStateColors.button.clearBackground,
        borderColor: componentStateColors.button.secondary,
        borderStyle: 'solid',
        color: componentStateColors.button.secondary,
      },
      /**
       * @deprecated - Should use `secondary.contained` or `secondary.outlined`
       */
      backgroundColor: baseColors.wagGreen,
    },
  },
  boxes: {
    picker: {
      date: {
        day: {
          recurringFrom: {
            backgroundImage: 'linear-gradient(to left, rgba(0, 191, 143, 0.3) 50%, white 50%)',
          },
          recurringTo: {
            backgroundImage: 'linear-gradient(to left, white 50%, rgba(0, 191, 143, 0.3) 50%)',
          },
        },
      },
    },
    service: {
      tile: {
        borderRadius: '16px',
        boxShadow: '0 4px 24px 0 rgba(0,0,0,0.2)',
      },
    },
    wave: {
      display: 'flex',
    },
    walkerProfile: {
      card: {
        borderRadius: '16px',
        borderStyle: 'solid',
        boxShadow: '0 2px 12px 0 rgba(0,0,0,0.09)',
      },
    },
  },
  textInputs: {
    default: {
      borderRadius: border.radii[3],
    },

    light: {
      backgroundColor: baseColors.white,
      border: 0,
      borderColor: baseColors.backgroundGray,
    },
  },
  textAreas: {
    default: {
      borderRadius: border.radii[3],
    },

    light: {
      border: 0,
      borderColor: baseColors.backgroundGray,
    },
  },
  texts: {
    heading: {
      fontFamily: typography.fonts.heading,
      fontSize: '28px',
      fontWeight: 'bold',
    },
    subHeading: {
      fontFamily: typography.fonts.subHeading,
      fontSize: '24px',
      fontWeight: 'bold',
    },
    body: {
      fontFamily: typography.fonts.body,
      fontSize: '18px',
    },
    title: {
      color: textControl.title,
      fontFamily: typography.fonts.title,
      fontSize: '28px',
    },
  },
};

const BaseTheme = {
  ...border,
  ...variants,
  ...typography,
  breakpoints,
  colors: {
    ...baseColors,
    ...appBaseColors,
    ...componentStateColors,
  },
  space,
  name: 'wag',
};

// Proxy "after" the "BaseTheme" collects all the theme
// const proxiedBaseTheme = deprecate(
//   [],
//   'colors',
//   prop => `Accessing color "${prop}" will soon be deprecated. Please avoid using it **directly** and start using semantic color names. See @waglabs/web-app-theme for more info.`,
// )(BaseTheme);

// export { proxiedBaseTheme as BaseTheme };
// export default proxiedBaseTheme;

export { BaseTheme };
export default BaseTheme;
