// @flow
import React from 'react';
import Flex from '@waglabs/flex';

type Props = {
  children: any,
  isFirst?: boolean,
  mt?: number,
};

export const FormFieldContainer = ({
  children,
  isFirst,
  mt,
  ...props
}: Props) => (
  <Flex
    flexDirection="column"
    position="relative"
    width={1}
    mt={!isFirst ? mt : 0}
    {...props}
  >
    {children}
  </Flex>
);

FormFieldContainer.defaultProps = {
  mt: '10px',
  isFirst: false,
};

export default FormFieldContainer;
