import { BaseTheme } from './index';
import { createControl } from './utils';

const baseColors = {
  athensGrey: '#D4DADC',
  baliHai: '#8E9DA2',
  black: '#000000',
  chetwodeBlue: '#6B7CAF',
  cultured: '#F4F6F6',
  deepSkyBlue: '#00AAE7',
  mySin: '#FAAB43',
  nightRider: '#333333',
  pattensBlue: '#D1E6EC',
  red: '#ff0000',
  snow: '#FBFBFB',
  transparent: 'transparent',
  white: '#FFFFFF',
  wildBlueYonder: '#7786B7',
  zircon: '#E5E9EA',
};

const appBaseColors = {
  primary: baseColors.deepSkyBlue,
secondary: baseColors.red,
};

const buttonControl = {
  // See variants::button
  clearBackground: baseColors.transparent,
  clearBorder: baseColors.transparent,
  clearColor: baseColors.deepSkyBlue,
  clearText: baseColors.white,

  disabledBackground: baseColors.athensGrey,
  disabledBorder: baseColors.baliHai,
  disabledBorderStyle: 'none',
  disabledColor: baseColors.baliHai,

  primary: appBaseColors.primary,
  secondary: appBaseColors.secondary,
};

const textControl = {
  activeTextLink: baseColors.deepSkyBlue,
  clear: baseColors.white,
  discount: baseColors.mySin,
  destructive: baseColors.red,
  header: baseColors.nightRider,
  light: baseColors.baliHai,
  title: baseColors.nightRider,
  word: baseColors.baliHai,
  dark: baseColors.black,
};

const flexControl = {
  light: baseColors.baliHai,
  background: baseColors.cultured,
  dark: baseColors.black,
  white: baseColors.white,
  transparent: baseColors.transparent,
};

const pickerControl = {
  date: {
    label: baseColors.deepSkyBlue,
    month: {
      activeColor: baseColors.deepSkyBlue,
      inactiveColor: baseColors.baliHai,
    },
    day: {
      pastEventBulletBackgroundColor: baseColors.baliHai,
      futureEventBulletBackgroundColor: baseColors.deepSkyBlue,
      todayColor: baseColors.deepSkyBlue,

      activeColor: baseColors.deepSkyBlue,
      inactiveColor: baseColors.baliHai,

      selectedBackgroundColor: baseColors.deepSkyBlue,
      selectedHighlightedBackgroundColor: 'rgba(0, 168, 230, 0.3)',
      highlightedBackgroundColor: 'rgba(0, 168, 230, 0.13)',
    },
  },
};

const navigationControl = {
  activeBackground: baseColors.deepSkyBlue,
  inactiveBackground: baseColors.baliHai,

  label: baseColors.nightRider,

  circleButtonColor: baseColors.deepSkyBlue,
};

const formControl = {
  errorBorder: baseColors.red,
  errorBackground: baseColors.red,
  errorColor: baseColors.white,
  horizontalRule: baseColors.athensGrey,

  checkbox: {
    activeBackground: baseColors.deepSkyBlue,
    activeBorder: baseColors.deepSkyBlue,

    inactiveBackground: baseColors.snow,
    inactiveBorder: baseColors.baliHai,
  },
  input: {
    activeBackground: baseColors.snow,
    activeBorder: baseColors.baliHai,
    activeColor: baseColors.black,

    inactiveBorder: baseColors.baliHai,
    inactiveBackground: baseColors.snow,
    inactiveColor: baseColors.baliHai,

    // Extras
    activeIcon: baseColors.deepSkyBlue,
    inactiveIcon: baseColors.baliHai,

    label: baseColors.baliHai,

    // The label under the fields
    validHelperLabel: baseColors.baliHai,
    invalidHelperLabel: baseColors.red,

    // checkmark that shows up
    checkmarkColor: baseColors.deepSkyBlue,
  },
  select: {
    activeBackground: baseColors.snow,
    activeBorder: baseColors.baliHai,
    activeColor: baseColors.black,

    activeOptionsBorder: baseColors.baliHai,

    inactiveBackground: baseColors.snow,
    inactiveBorder: baseColors.baliHai,
    inactiveColor: baseColors.baliHai,
    options: {
      activeBackground: baseColors.deepSkyBlue,
      activeColor: baseColors.white,

      inactiveBackground: baseColors.white,
      inactiveColor: baseColors.black,
    },
  },
  textarea: {
    activeBackground: baseColors.snow,
    activeBorder: baseColors.baliHai,
    activeColor: baseColors.black,

    inactiveBorder: baseColors.baliHai,
    inactiveBackground: baseColors.snow,
    inactiveColor: baseColors.baliHai,
  },
};

const serviceControl = {
  tile: {
    walk: {
      title: baseColors.nightRider,
      subTitle: baseColors.baliHai,
    },
  },
  slider: {
    bullet: {
      activeBackground: baseColors.deepSkyBlue,
      inactiveBackground: baseColors.baliHai,
    },
  },
};

const walkServiceControl = {
  slider: {
    bullet: {
      activeBackground: baseColors.baliHai,
      inactiveBackground: baseColors.baliHai,
    },
  },
};

const pastServicesControl = {
  services: {
    item: {
      unreadColor: baseColors.nightRider,
      readColor: baseColors.nightRider,
    },
  },
  walkers: {
    item: {
      color: baseColors.nightRider,
    },
  },
};

const bookingSuccessControl = {
  headingText: baseColors.deepSkyBlue,
  bodyText: baseColors.black,
};

const reportCardControl = {
  walkStatus: {
    background: baseColors.snow,
    labeledIconBackground: baseColors.athensGrey,
    labelColor: baseColors.nightRider,
  },
  walkerNote: {
    background: baseColors.snow,
    text: baseColors.nightRider,
  },
};

const progressBarControl = {
  normalBackgroundColor: baseColors.athensGrey,
  normalForegroundColor: baseColors.deepSkyBlue,
  invertedBackgroundColor: baseColors.athensGrey,
  invertedForegroundColor: baseColors.deepSkyBlue,
};

const waveControl = {
  textColor: baseColors.nightRider,
  backgroundColor: baseColors.white,
  backButtonColor: appBaseColors.primary,
};

const rangeInputControl = {
  thumbColor: appBaseColors.primary,
  trackColor: baseColors.athensGrey,
};

const modalControl = {
  backgroundColor: baseColors.white,
  secondaryBackgroundColor: baseColors.cultured,
  textColor: baseColors.nightRider,
};

const badgeControl = {
  backgroundColor: baseColors.deepSkyBlue,
  textColor: baseColors.white,
};

const avatarControl = {
  borderColor: baseColors.deepSkyBlue,
  secondaryBorderColor: baseColors.chetwodeBlue,
};

const paymentControl = {
  creditCard: {
    activeBorder: baseColors.deepSkyBlue,
    inactiveBorder: baseColors.baliHai,
  },
  promo: {
    activeBorder: baseColors.deepSkyBlue,
    inactiveBorder: baseColors.baliHai,
  },
  stripeForm: {
    text: {
      inactiveColor: baseColors.baliHai,
      activeColor: baseColors.deepSkyBlue,
    },
  },
  summary: {
    activeBorder: baseColors.baliHai,
    inactiveBorder: baseColors.baliHai,
  },
};

const walkerProfileControl = {
  avatar: {
    border: baseColors.zircon,
  },
  banner: {
    button: {
      contact: {
        background: baseColors.deepSkyBlue,
        border: baseColors.deepSkyBlue,
        color: baseColors.white,
      },
    },
    text: {
      word: baseColors.black,
    },
  },
  readMore: {
    color: baseColors.deepSkyBlue,
  },
};

const componentStateColors = Object.assign(
  {},
  createControl('avatar')(avatarControl),
  createControl('badge')(badgeControl),
  createControl('bookingSuccess')(bookingSuccessControl),
  createControl('button')(buttonControl),
  createControl('flex')(flexControl),
  createControl('form')(formControl),
  createControl('modal')(modalControl),
  createControl('navigation')(navigationControl),
  createControl('pastServices')(pastServicesControl),
  createControl('payment')(paymentControl),
  createControl('picker')(pickerControl),
  createControl('progressBar')(progressBarControl),
  createControl('rangeInput')(rangeInputControl),
  createControl('reportCard')(reportCardControl),
  createControl('service')(serviceControl),
  createControl('text')(textControl),
  createControl('walkerProfile')(walkerProfileControl),
  createControl('walkService')(walkServiceControl),
  createControl('wave')(waveControl),
);

const typography = {
  // fonts, fontSizes, fontWeights, lineHeights, letterSpacings
  fonts: {
    heading: 'Arial, sans-serif',
    subHeading: 'Arial, sans-serif',
    body: 'Arial, sans-serif',
    title: 'Arial, sans-serif',
  },
};

const variants = {
  buttons: {
    clear: {
      backgroundColor: componentStateColors.button.clearBackground,
      borderColor: componentStateColors.button.clearBorder,
      borderStyle: 'solid',
      color: componentStateColors.button.clearColor,
    },
    primary: {
      contained: {
        backgroundColor: componentStateColors.button.primary,
        borderColor: componentStateColors.button.primary,
        borderStyle: 'solid',
        color: componentStateColors.button.clearText,
      },
      outlined: {
        backgroundColor: componentStateColors.button.clearBackground,
        borderColor: componentStateColors.button.primary,
        borderStyle: 'solid',
        color: componentStateColors.button.primary,
      },
    },
    alternate: {
      // "alternate primary" - champagne primary button, but same for partner theme
      contained: {
        backgroundColor: componentStateColors.button.primary,
        borderColor: componentStateColors.button.primary,
        borderStyle: 'solid',
        color: componentStateColors.button.clearText,
      },
      outlined: {
        backgroundColor: componentStateColors.button.clearBackground,
        borderColor: componentStateColors.button.primary,
        borderStyle: 'solid',
        color: componentStateColors.button.primary,
      },
    },
    secondary: {
      contained: {
        backgroundColor: componentStateColors.button.secondary,
        borderColor: componentStateColors.button.secondary,
        borderStyle: 'solid',
        color: componentStateColors.button.clearText,
      },
      outlined: {
        backgroundColor: componentStateColors.button.clearBackground,
        borderColor: componentStateColors.button.secondary,
        borderStyle: 'solid',
        color: componentStateColors.button.secondary,
      },
      // TODO - Avoid using this and deprecate it
      backgroundColor: baseColors.wagGreen,
    },
  },
  boxes: {
    picker: {
      date: {
        day: {
          recurringFrom: {
            backgroundImage: 'linear-gradient(to left, rgba(0, 168, 230, 0.3) 50%, white 50%)',
          },
          recurringTo: {
            backgroundImage: 'linear-gradient(to left, white 50%, rgba(0, 168, 230, 0.3) 50%)',
          },
        },
      },
    },
    service: {
      tile: {
        borderRadius: '5px',
        boxShadow: `0 0 5px 0 ${baseColors.athensGrey}`,
      },
    },
    wave: {
      display: 'none',
    },
    walkerProfile: {
      card: {
        borderRadius: '16px',
        borderStyle: 'solid',
        boxShadow: '0 2px 12px 0 rgba(0,0,0,0.09)',
      },
    },
  },
  texts: {
    heading: {
      color: textControl.header,
      fontFamily: typography.fonts.heading,
      fontSize: '28px',
      fontWeight: 'bold',
    },
    subHeading: {
      color: textControl.header,
      fontFamily: typography.fonts.subHeading,
      fontSize: '24px',
    },
    body: {
      color: textControl.word,
      fontFamily: typography.fonts.body,
      fontSize: '18px',
    },
    title: {
      color: textControl.title,
      fontFamily: typography.fonts.title,
      fontSize: '28px',
      fontWeight: 'bold',
    },
  },
};

export const PetcoTheme = {
  ...BaseTheme,
  ...variants,
  ...typography,
  colors: {
    // TODO - Remove support of colors from baseTheme (the baseTheme should have semantic names)
    ...BaseTheme.colors,
    ...appBaseColors,
    ...componentStateColors,
  },
  name: 'petco',
};
