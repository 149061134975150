// @flow
import React, { type ReactNode, ReactType } from 'react';

import {
  BackgroundProps,
  FlexboxProps,
  LayoutProps,
  SpaceProps,
  BackgroundColorProps,
  BorderProps,
  ColorProps,
  GridProps,
  PositionProps,
  ShadowProps,
  TypographyProps,
} from 'styled-system';

import H2 from '@waglabs/h2';
import H1 from '@waglabs/h1';
import H3 from '@waglabs/h3';
import Span from '@waglabs/span';
import Text from '@waglabs/text';

type StyledSystemProps = BackgroundProps &
  BackgroundColorProps &
  BorderProps &
  ColorProps &
  FlexboxProps &
  GridProps &
  LayoutProps &
  PositionProps &
  ShadowProps &
  SpaceProps &
  TypographyProps;

// Place the default props here specific to each variant
const variantMapping = {
  h1: (props: StyledSystemProps) => (
    <H1
      {...props}
    />
  ),
  h2: (props: StyledSystemProps) => (
    <H2
      {...props}
    />
  ),
  h3: (props: StyledSystemProps) => (
    <H3
      {...props}
    />
  ),
  body1: (props: StyledSystemProps) => (
    <Text
      {...props}
    />
  ),
  body2: (props: StyledSystemProps) => (
    <Span
      {...props}
    />
  ),
  legal: (props: StyledSystemProps) => (
    <Text
      {...props}
    />
  ),
};

type Variants = $Keys<typeof variantMapping>;

export type Props = {
  children: ReactNode;
  component?: ReactType;
  hover?: boolean;
  variant?: Variants;
};

const defaults = {
  fontSize: (semantic: Variants) => ({
    fontSize: [`mobile-${semantic}`, `tablet-${semantic}`, `desktop-${semantic}`],
  }),
};

export const Typography = ({
  color = 'medGray',
  variant = 'body1',
  fontFamily = 'muli',
  component,
  ...rest
}: Props) => {
  const Component = component || variantMapping[variant];
  return (
    <Component
      color={color}
      fontFamily={fontFamily}
      {...defaults.fontSize(variant)}
      {...rest}
    />
  );
};
