// @flow
import React, { Component } from 'react';
import { TextArea } from '..';
import { AnimatedInputLabel } from '../..';


type Props = {
  value: string,
  onChange: Function,
  name: string,
  label: string,
  onBlur: Function,
  onFocus: Function,
};

type State = {
  isFocused: boolean,
};

class TextAreaField extends Component<Props, State> {
  state = {
    isFocused: false,
  }

  handleFocus = (e: any) => {
    const {
      onFocus,
    } = this.props;

    if (onFocus) {
      onFocus(e);
    }

    this.setState({
      isFocused: true,
    });
  }

  handleBlur = (e: any) => {
    const {
      onBlur,
    } = this.props;

    if (onBlur) {
      onBlur(e);
    }

    this.setState({
      isFocused: false,
    });
  }

  render() {
    const {
      label,
      value,
      onChange,
      name,
      dataTestId,
      ...props
    } = this.props;

    const {
      isFocused,
    } = this.state;

    return (
      <>
        <AnimatedInputLabel
          isFocused={value.length > 0 || isFocused}
          name={name}
        >
          {label}
        </AnimatedInputLabel>

        <TextArea
          {...props}
          name={name}
          data-testid={dataTestId}
          value={value}
          border="1px solid #dcdcdc"
          borderRadius="4px"
          fontSize="1rem"
          px="15px"
          py="30px"
          height="150px"
          width="100%"
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onChange={onChange}
        />
      </>
    );
  }
}


export default TextAreaField;
