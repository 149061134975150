// @flow
import styled from 'styled-components';
import {
  width,
  height,
  space,
  color,
  display,
  maxWidth,
  minHeight,
  maxHeight,
  zIndex,
  opacity,
  position,
} from 'styled-system';

const StyledForm = styled.form`
  ${width}
  ${height}
  ${position}
  ${maxWidth}
  ${space}
  ${color}
  ${minHeight}
  ${maxHeight}
  ${zIndex}
  ${display}
  ${opacity}
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: ${props => props.boxSizing || 'content-box'};
  transition: ${props => props.transition || 'none'};
  visibility: ${props => props.visibility || 'visible'};

  /* We need to use this to keep animations working in safari */
  backface-visibility: hidden;
  perspective: 1000;
  transform-style: preserve-3d;
`;

export default StyledForm;
