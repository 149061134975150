// @flow
import styled from 'styled-components';
import {
  borderColor,
  borderRadius,
  borders,
  boxShadow,
  color,
  fontFamily,
  fontSize,
  fontWeight,
  height,
  lineHeight,
  space,
  textAlign,
  variant,
  width,
} from 'styled-system';

const textAreaStyle = variant({
  key: 'textAreas',
});

const TextInput = styled.textarea.attrs({
  type: 'text',
})`

  /* Variants should be placed at the top to be override */
  ${textAreaStyle}
  ${borderRadius}
  ${borders}
  ${borderColor}
  ${boxShadow}
  ${color}
  ${fontFamily}
  ${fontSize}
  ${fontWeight}
  ${height}
  ${lineHeight}
  ${space}
  ${textAlign}
  ${width}
  box-sizing: border-box;
  font-family: ${props => props.theme[props.fontFamily] || 'Helvetica, Arial, sans-serif'};
  display: ${props => props.display || 'inline'};
  text-indent: ${props => props.textIndent || '0'};

  background-color: ${props => props.theme.colors.form.textarea.inactiveBackground};
  border: 1px solid ${props => props.theme.colors.form.textarea.inactiveBorder};
  color: ${props => props.theme.colors.form.textarea.inactiveColor};

  &::placeholder {
    ${fontSize}
    ${'' /* TODO: [GROW-2196] fix all versions of TextArea component, so that this does not cause a breaking change. */}
    padding-top: 0px;
    color: ${props => props.theme.colors.form.textarea.inactiveColor};
    font-family: ${props => props.theme[props.fontFamily] || 'Helvetica, Arial, sans-serif'};
  }

  ${props => props.value && `
    background-color: ${props.theme.colors.form.textarea.activeBackground};
    border: 1px solid ${props.theme.colors.form.textarea.activeBorder};
    color: ${props.theme.colors.form.input.activeColor};
  `}

  &:focus {
    outline: none;
    background-color: ${props => props.theme.colors.form.textarea.activeBackground};
    border: 1px solid ${props => props.theme.colors.form.textarea.activeBorder};
    color: ${props => props.theme.colors.form.textarea.activeColor};
  }

  ${props => !props.resizeable && `
    resize: none;
  `}

  ${props => props.error && `
    border: 1px solid ${props.theme.colors.form.errorBorder || 'red'};
  `}
`;


TextInput.defaultProps = {
  variant: 'default',
  height: [95, 110],
  borderRadius: 3,
  resizeable: false,
};

export default TextInput;
