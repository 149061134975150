// @flow
import React from 'react';

// prettier-ignore
const CheckMark = (props: SVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.7 19.7" {...props}>
    <path fill="currentColor" d="M19.3.4a1.2 1.2 0 0 0-1.8 0L5.6 12.6l-3-3.4A1.5 1.5 0 0 0 .7 9a1.8 1.8 0 0 0-.4 2.4l4.3 7a2.6 2.6 0 0 0 .6.6 2.3 2.3 0 0 0 3.3-.5l.1-.2L19.4 2.2a1.5 1.5 0 0 0-.1-1.8z" />
  </svg>
);

export default CheckMark;
