// @flow
import React, { Component } from 'react';
import Box from '@waglabs/box';
import { Icon } from '@waglabs/icon';
import TextInput from '../TextInput';
import CheckMark from './CheckMark';

type Props = {
  isValid?: boolean,
  validFieldIconName?: string,
  value: string,
  variant?: string,
};

type State = {
  isFocused: boolean,
  displayPassword: boolean,
}

class StyledTextInput extends Component<Props, State> {
  static defaultProps = {
    isValid: true,
    validFieldIconName: 'checkmark',
  };

  state = {
    displayPassword: false,
  };

  getPaddingRight = () => {
    const {
      isValid,
      touched,
      type,
    } = this.props;

    if (type === 'password') {
      if (isValid && touched) {
        return '65px';
      }
    }

    if (isValid && touched) {
      return '35px';
    }

    return '10px';
  }

  getType = () => {
    const {
      type,
    } = this.props;

    const {
      displayPassword,
    } = this.state;

    if (type === 'password' && displayPassword) {
      return 'text';
    }

    return type;
  }

  togglePassword = () => this.setState(({ displayPassword }) => ({
    displayPassword: !displayPassword,
  }));

  render() {
    const {
      value,
      type,
      isValid,
      touched,
      onRef,
      validFieldIconName,
      ...props
    } = this.props;

    return (
      <>
        <TextInput
          height={50}
          width={1}
          fontSize={4}
          fontFamily="body"
          boxShadow="none"
          borderRadius={3}
          color="medGray"
          pb={0}
          px={2}
          pr={this.getPaddingRight}
          textIndent="10px"
          type={this.getType()}
          value={value}
          {...props}
        />

        {type === 'password' && (
          <Box
            position="absolute"
            width="20px"
            height="20px"
            top="17px"
            cursor="pointer"
            right={(touched && isValid) ? '45px' : '15px'}
          >
            <Icon
              width="20px"
              height="20px"
              onClick={this.togglePassword}
              name="eyeball"
            />
          </Box>
        )}

        {touched && isValid && (
          <Box
            position="absolute"
            width="25px"
            height="25px"
            top="13px"
            right="10px"
            color="form.input.checkmarkColor"
          >
            {validFieldIconName === 'checkmark' ? (
              <CheckMark />
            ) : (
              <Icon
                width="25px"
                height="25px"
                name={validFieldIconName}
              />
            )}
          </Box>
        )}
      </>
    );
  }
}

export default StyledTextInput;
